import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useNavigate } from "react-router-dom";

function MyNav() {
  const navigate = useNavigate();
  return (
    <>
      <Navbar bg="primary" data-bs-theme="dark" sticky="top">
        <Container>
          <Navbar.Brand onClick={() => navigate("/")} className="text-white">
            Pauk Thee
          </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link onClick={() => navigate("/twod")} className="text-white">
              2D Calendar
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/threed")}
              className="text-white"
            >
              3D Calendar
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default MyNav;
