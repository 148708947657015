import React from "react";
import { Helmet } from "react-helmet";

export default function BannerAd() {
  return (
    <div>
      <Helmet>
        <script>
          {`
              (function(d,z,s){
                s.src='https://'+d+'/401/'+z;
                try {
                  (document.body||document.documentElement).appendChild(s)
                } catch(e) {
                  console.error(e);
                }
              })('aistekso.net',7489042,document.createElement('script'))
            `}
        </script>
      </Helmet>
    </div>
  );
}
