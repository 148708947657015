import React, { useEffect, useState } from "react";
import { getData } from "../services/api";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../components/loading";
import InterAd from "../components/InterAd";

const PostCard = ({ post, navigate }) => {
  const image =
    post.image == "" ? require("../assets/images/bg.png") : post.image;
  return (
    <Col lg="4" className="my-2">
      <Card onClick={() => navigate("/postdetail", { state: post })}>
        <Card.Header>{post.title}</Card.Header>
        <Card.Body>
          <Card.Img src={image} width={"200px"} height={"200px"} />
        </Card.Body>
      </Card>
    </Col>
  );
};
export default function Posts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchPosts();
  }, []);
  const fetchPosts = async () => {
    setLoading(true);
    const resData = await getData("/app4/posts");
    setPosts(resData);
    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Row className="mt-2">
        <InterAd />
        {posts.map((post) => {
          return <PostCard post={post} navigate={navigate} />;
        })}
      </Row>
    </Container>
  );
}
