import React, { useEffect, useState } from "react";
import { getData } from "../services/api";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "../components/loading";
import { Helmet } from "react-helmet";
import BannerAd from "../components/BannerAd";

const ImageCard = ({ card }) => {
  return (
    <Col md={3} className="mt-3">
      <Card>
        <Card.Body>
          <Link to={`/imgdetail`} state={card}>
            <Card.Img variant="top" src={card.image} height={"300px"} />
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default function DailyCards() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const data = await getData("/cards/twod");
    setImages(data);
    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <Container>
      <Row>
        <BannerAd />
        {images.map((image) => {
          return <ImageCard key={image.id} card={image} />;
        })}
      </Row>
    </Container>
  );
}
