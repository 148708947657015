import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import Home from "./pages/Home";
import Twod from "./pages/Twod";
import Threed from "./pages/Threed";
import Thaicards from "./pages/Thaicards";
import Nav from "./components/nav";

import "./assets/css/app.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Present from "./pages/Present";
import ImageDetail from "./pages/ImageDetail";
import DailyCards from "./pages/DailyCards";
import WeeklyCards from "./pages/WeeklyCards";
import Posts from "./pages/Posts";
import PostDetail from "./pages/PostDetail";
import Premium from "./pages/Premium";
import Lucky from "./pages/Lucky";

function App() {
  const location = useLocation();

  const hideNavbarPaths = ["/lucky"];
  const shouldHideNavbar = hideNavbarPaths.includes(location.pathname);

  return (
    <>
      {!shouldHideNavbar && <Nav />}
      <Analytics />
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/twod" Component={Twod} />
        <Route path="/threed" Component={Threed} />
        <Route path="/thaicards" Component={Thaicards} />
        <Route path="/dailycards" Component={DailyCards} />
        <Route path="/weeklycards" Component={WeeklyCards} />
        <Route path="/imgdetail" Component={ImageDetail} />
        <Route path="/posts" Component={Posts} />
        <Route path="/postdetail" Component={PostDetail} />
        <Route path="/premium" Component={Premium} />
        <Route path="/lucky" Component={Lucky} />
      </Routes>
    </>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
