export const presentData = [
  {
    title: "2D Daily Cards",
    image: require("../assets/images/daily.jpg"),
    path: "/dailycards",
  },
  {
    title: "2D Weekly Cards",
    image: require("../assets/images/week.jpg"),
    path: "/weeklycards",
  },
  {
    title: "2D Posts",
    image: require("../assets/images/post.jpg"),
    path: "/posts",
  },
  {
    title: "2D Premium",
    image: require("../assets/images/bg.png"),
    path: "/premium",
  },
];
