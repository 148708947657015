import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Loading() {
  const image = require("../assets/images/loading.webp");
  return (
    <Container className="loadingBox">
      <img src={image} className="align-center d-block" width={"100%"} />
    </Container>
  );
}
