import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import PushAd from "../components/PushAd";
export default function ImageDetail(props) {
  const { state } = useLocation();
  return (
    <Container>
      <Row className="mt-5">
        <PushAd />
        <Col md="6" className="mx-auto">
          <Card>
            <Card.Img src={state.image} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
