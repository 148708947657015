import React, { useEffect, useState } from "react";
import { getData } from "../services/api";
import { Col, Container, Row } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";

export default function Lucky() {
  const [post, setPost] = useState({});
  const [searchParam, setSearchParam] = useSearchParams();
  const name = searchParam.get("name");
  const smallBanner = require("../assets/images/banner.gif");
  const mediumBanner = require("../assets/images/medium.gif");
  console.log(name);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const response = await getData("/app4/cards/present");
    setPost(response);
    console.log(response);
  };
  return (
    <Container>
      <Row className="my-2 justify-content-center">
        <Col md="3">
          <img src={name === "twod" ? post.twod : post.lucky} style={{ width: 320, height: 400, backgroundClip: "#444" }} />
        </Col>
      </Row>
      <Row className="my-2 justify-content-center ">
        <Col md="3">
          <a href="https://bit.ly/3KDCwO5" className="align-center" target="_blank">
            <img src={name === "twod" ? smallBanner : mediumBanner} style={{ width: 320 }} />
          </a>
        </Col>
      </Row>
    </Container>
  );
}
