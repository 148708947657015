import React from "react";
import { Helmet } from "react-helmet";

export default function PushAd() {
  return (
    <div>
      <Helmet>
        <script
          src="https://phicmune.net/pfe/current/tag.min.js?z=7489035"
          data-cfasync="false"
          async
        ></script>
      </Helmet>
    </div>
  );
}
