import React, { useEffect, useState } from "react";
import { presentData } from "../services/data";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InterAd from "../components/InterAd";
const PresentCard = ({ card }) => {
  return (
    <Col md="6" className="g-4">
      <Card className="p-2">
        <Card.Title className="text-center">{card.title}</Card.Title>
        <Link to={card.path}>
          <Card.Img src={card.image} height={"350px"} />
        </Link>
      </Card>
    </Col>
  );
};
export default function Home() {
  return (
    <Container>
      <Row className="my-2">
        <InterAd />
        {presentData.map((data) => {
          return <PresentCard key={data.path} card={data} />;
        })}
      </Row>
    </Container>
  );
}
