import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import TextWithLineBreaks from "../components/linebreak";
import BannerAd from "../components/BannerAd";

export default function PostDetail() {
  const { state } = useLocation();
  const image =
    state.image === "" ? require("../assets/images/bg.png") : state.image;

  return (
    <Container>
      <Row className="mt-4">
        <BannerAd />
        <Col lg="8" className="mx-auto">
          <Card className="py-3">
            <Card.Title className="text-center">{state.title}</Card.Title>
            <Card.Body>
              <TextWithLineBreaks text={state.content} />
            </Card.Body>
            <Card.Img src={image} height="300px" style={{ padding: "10px" }} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
