import React from "react";
import { Helmet } from "react-helmet";

export default function InterAd() {
  return (
    <div>
      <Helmet>
        <script
          async="async"
          data-cfasync="false"
          src="//thubanoa.com/1?z=7488998"
        ></script>
      </Helmet>
    </div>
  );
}
