import React, { useEffect, useState } from "react";
import { getData } from "../services/api";
import { Card, Col, Container, Row } from "react-bootstrap";

const ImageCard = ({ card }) => {
  return (
    <Col md={2} className="mt-3">
      <Card>
        <Card.Img
          variant="top"
          src={card.image}
          width={"200px"}
          height={"400px"}
        />
      </Card>
    </Col>
  );
};
export default function Thaicards() {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const data = await getData("/cards/week");
    setImages(data);
    setLoading(false);
  };
  if (loading) {
    return null;
  }
  return (
    <Container>
      <Row>
        {images.map((image) => {
          return <ImageCard card={image} />;
        })}
      </Row>
    </Container>
  );
}
