import React, { useEffect, useState } from "react";
import { getData } from "../services/api";
import { Col, Container, Row } from "react-bootstrap";
import Loading from "../components/loading";
import BannerAd from "../components/BannerAd";

const ThreedCard = ({ post }) => {
  return (
    <Col md="5" className="bg-light my-2 mx-auto mx-2 p-2">
      <div className="d-flex justify-content-between  align-items-center">
        <div>
          <p className="text-dark">Date</p>
          <p className="text-dark fw-bold">{post.date}</p>
        </div>
        <h3 className="px-4 py-2 b-2 bg-primary rounded-2 text-white">
          {post.threeNum}
        </h3>
      </div>
    </Col>
  );
};
export default function Threed() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchPosts = async () => {
      const resData = await getData("/threed");
      setPosts(resData);
      setLoading(false);
    };

    fetchPosts();
  });
  if (loading) return <Loading />;
  return (
    <Container>
      <Row className="mx-2">
        <BannerAd />
        {posts.map((post) => {
          return <ThreedCard post={post} />;
        })}
      </Row>
    </Container>
  );
}
